<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Clientes</h4>
            <div class="small text-muted">Adminsitración de todos los clientes</div>
          </b-col>
          <b-col sm="5">

            <!--<b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Cliente">
              <i class="fa fa-plus"></i> Agregar
            </b-button>-->
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Clientes">
              <b-dropdown-item @click="add()"><i class="fa fa-plus"></i> Agregar Cliente</b-dropdown-item>
              <b-dropdown-item @click="openImportCSV()"><i class="fa fa-file-excel-o"></i> Gestor de Importaciones</b-dropdown-item>
            </b-dropdown>

            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>

          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros" class="mb-2">
          <b-col sm="10">
            <b-row>
              <b-col sm="5" md="5">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese un código, nombre, teléfono o email" v-model="filters.name" v-on:keyup.enter.native="filterCustomers"></b-form-input>              
                  </b-input-group>
                </b-form-group>
              </b-col>                      
              <b-col sm="4" md="3">
                <v-select :options="arr.filter.status" v-model="filters.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
              </b-col> 
              <b-col sm="4" md="3">
                <b-button variant="outline-dark" @click="filterCustomers()" size="sm" class="mt-1">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-dropdown right text="Exportar" size="sm" variant="outline-warning" class="pull-right mt-3">
              <b-dropdown-item>
                <export-excel
                    :data = "arr.exportAll"
                    worksheet = "Listado de Clientes"
                    name = "list-customers.xls">
                    Listado de Clientes
                </export-excel>
              </b-dropdown-item>
              <b-dropdown-item>
                <export-excel
                    :data = "arr.exportGoogleContacts"
                    worksheet = "Google Contacts"
                    name = "google-contact.xls">
                    Google Contacts
                </export-excel>
              </b-dropdown-item>
            </b-dropdown>
            
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0 table-customers-custom"
                    ref="table"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                                        

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>                    
                
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:row-details="row">
                    <b-card no-body>
                      <b-tabs card>
                        <b-tab title="Usuarios" active>
                          <b-card-text>
                            <b-row>
                              <b-col>
                                <b-button @click="createUser(row.item)" type="button" variant="dark" size="sm" class="btn-pill float-right ml-2 mb-2" v-b-tooltip.hover title="Agregar Usuario de Cliente">
                                  <i class="fa fa-plus"></i> Agregar
                                </b-button>

                                <b-button @click="openFindUser(row.item)" type="button" variant="outline-dark" size="sm" class="btn-pill float-right ml-2 mb-2" v-b-tooltip.hover title="Agregar Usuario de Cliente">
                                  <i class="fa fa-search"></i> Buscar
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table class="mb-0"
                                    responsive
                                    head-variant="light"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    stacked="sm"
                                    :items="tableUsers.items"
                                    :fields="tableUsers.fields"
                                    v-if="tableUsers.items.length">

                                  <template v-slot:cell(roles)="row">
                                    <div v-if="row.item">
                                      <div v-if="row.item.roles">
                                        <b-badge  variant="secondary"
                                                  class="mr-2 badge-module-customers-custom"
                                                  v-for="value in filtroCliente(row.item.roles)"
                                                  :key="value.id">
                                          {{value.name}}
                                        </b-badge>
                                      </div>
                                    </div>
                                  </template>

                                  <template v-slot:cell(password_visible)="row">                                    
                                    <b-link @click="viewAccess(row.item)" href="#">
                                      <b>
                                        Ver <b-icon icon="chevron-double-right"></b-icon>
                                      </b>
                                    </b-link>                                                                         
                                  </template>

                                  <template v-slot:cell(f_action)="row">
                                    <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                      <b-dropdown-item @click="editRolesUser(row.item)">
                                        <i class="fa fa-edit mr-0 ml-0" style="color:blue"></i> Editar Roles
                                      </b-dropdown-item>

                                      <b-dropdown-item @click="removeUser(row.item)">
                                        <i class="fa fa-user-times ml-0 mr-0" style="color:red"></i> Eliminar Usuario
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </template>

                                </b-table>
                                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                              </b-col>
                            </b-row>
                          </b-card-text>
                        </b-tab>
                      </b-tabs>
                    </b-card>
                </template>

                <template v-slot:cell(f_users)="data">
                  <div class="customers-text-success-custom text-module-customers-custom" v-if="data.item.users.length">{{ data.item.users.length }} usuario/s asignados</div>
                  <div class="customers-text-danger-custom text-module-customers-custom" v-else>No se encontraron usuarios asignados</div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(code)="data">
                  <b>{{ data.item.code }}</b>
                </template>

                <template v-slot:cell(name)="data">                  
                  <div class="customer-table-code" v-if="data.item.code">
                    <u>Cod:</u> {{data.item.code}}<br>
                  </div>
                  <b-link variant="link" @click="goCustomerView(data.item)">
                    <b>{{data.item.name}}</b>
                  </b-link>                  
                </template>

                <template v-slot:cell(image)="data">
                  <b-avatar :src="data.item.image"
                            v-if="data.item.image">
                  </b-avatar>                  
                  <b-avatar v-else
                            icon="building"
                            variant="dark">
                  </b-avatar>                                  
                </template>

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template>

                <template v-slot:cell(commissions)="data">
                  {{data.item.commissions}} %                  
                </template>

                <template v-slot:cell(f_action)="data">                  
                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right"
                              @show="setConfigTableCustomersShow"
                              @hide="setConfigTableCustomersHide">
                    <b-dropdown-header v-if="parameters.isMarketplace">Marketplace</b-dropdown-header>
                    <b-dropdown-item @click="openCommisions(data.item)" v-if="parameters.isMarketplace">
                      <i class="fa fa-percent crud-customer-action-icon ml-0 mr-0"></i> Comisiones
                    </b-dropdown-item>                    

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)" v-if="access.elements.editCustomers">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteCustomers">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>

                    <b-dropdown-header>Accesos</b-dropdown-header>                    
                    <b-dropdown-item @click="onRowSelected(data.item)">
                      <i class="fa fa-users mr-0 ml-0" style="color:green"></i> Usuarios
                    </b-dropdown-item>

                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row class="mt-2">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>            
          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterCustomers()" />
            </nav>               
          </b-col>
        </b-row>

      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
        <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
          <b-tab title="General">
            <b-row>
              <b-col md="5">
                <b-form-group label="Logo">
                  <Imagen :images="crud.form.image" @load-image="crud.form.image = $event" typeImage="Logo"/>
                </b-form-group>  
              </b-col>          
              <b-col md="7">
                <b-row>
                  <b-col md="12">
                    <b-form-checkbox v-model="crud.form.active" 
                                      switch 
                                      size="sm" 
                                      class="mt-4 pull-right">
                      Activo
                    </b-form-checkbox>            
                  </b-col>               
                  <b-col md="12">
                    <b-form-group label="Código">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.code"
                                    required
                                    placeholder="Ingresar un código">
                      </b-form-input>
                    </b-form-group>
                  </b-col>              
                  <b-col md="12">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.form.name"
                                    required
                                    placeholder="Ingresar un nombre">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-form-group label="Email">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.email"
                                required
                                placeholder="Ingresar un email">
                  </b-form-input>
                </b-form-group>
              </b-col>            
              <b-col md="12">
                <b-form-group label="Teléfono" description="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.phone"
                                required
                                placeholder="Ingresar un teléfono">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Observaciones">
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea
                    v-model="crud.form.observations"
                    placeholder="Ingresar una observación..."
                    rows="3"
                    size="sm"
                  ></b-form-textarea>                  
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Transporte">
                  <b-form-textarea
                    v-model="crud.form.observations_delivery"
                    placeholder="Ingresar el transporte que utiliza por defecto..."
                    rows="3"
                    size="sm"
                  ></b-form-textarea>                  
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Dirección de Entrega">
                  <b-form-textarea
                    v-model="crud.form.observations_delivery_address"
                    placeholder="Ingresar la dirección de entrega por defecto..."
                    rows="3"
                    size="sm"
                  ></b-form-textarea>                  
                </b-form-group>
              </b-col>                            
            </b-row>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">          
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD USER -->
      <b-modal v-model="modal.formUser.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formUser.title}}
        </div>

        <b-row>
          <b-col>
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formUser.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input type="email"
                            size="sm"
                            v-model="crud.formUser.email"
                            required
                            placeholder="Ingresar un email">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Contraseña">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formUser.password"
                            required
                            placeholder="Ingresar una contraseña">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Roles">
              <b-form-select v-model="crud.formUser.roles" :options="arr.roles" multiple :select-size="4"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formUser.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveUser()">Guardar</b-button>
        </div>
      </b-modal>

      <!-- ROLES USER -->
      <b-modal v-model="modal.formUserRoles.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formUserRoles.title}}
        </div>

        <b-row>
          <b-col>
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formUser.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Roles">
              <b-form-select v-model="crud.formUser.roles" :options="arr.roles" multiple :select-size="4"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formUserRoles.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveUserRoles()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- FIND USER -->
      <b-modal v-model="modal.formUserFind.active"
               size="lg"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formUserFind.title}}
        </div>

        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formUserFind.email"
                            required
                            placeholder="Ingresar un nombre o email de usuario"
                            v-on:keyup.enter.native="findUser()">
              </b-form-input>
              <b-input-group-append>
                <b-button variant="dark" size="sm" @click="findUser()">
                  <i class="fa fa-search"></i>
                  Buscar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="light"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableUsersFind.items"
                    :fields="tableUsersFind.fields"
                    v-if="tableUsersFind.items.length">

              <template v-slot:cell(f_action)="row">
                <b-button variant="outline-dark" size="sm" @click="selectUser(row.item)">
                  Seleccionar
                  <b-icon icon="chevron-double-right"></b-icon>
                </b-button>
              </template>

            </b-table>
            <b-alert v-if="modal.formUserFind.alertResult" variant="warning" show>
              No se encontraron registros
            </b-alert>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formUserFind.active=false">Cancelar</b-button>                    
        </div>
      </b-modal>

      <!-- PASSWORD -->
      <b-modal v-model="modal.password.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.password.title}}
        </div>

        <b-row>
          <b-col>
            
            <b-input-group class="w-100 mb-1">
              <b-input-group-append>
                <div class="input-group-text">
                  <b-icon class="mb-0" icon="person-fill"></b-icon>
                </div>
              </b-input-group-append>
              <b-form-input  type="text"
                            size="md"
                            v-model="crud.password.email"
                            readonly>
              </b-form-input>
            </b-input-group>

            <b-input-group class="w-100">                      
              <b-form-input :type="crud.formUser.typePassword"
                            size="md"
                            v-model="crud.password.password"
                            readonly>
              </b-form-input>
              <b-input-group-append>
                <b-button variant="secondary" size="sm" @click="changeTypePassword()">
                  <i class="icon material-icons">{{crud.formUser.iconPassword}}</i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="pull-right" @click="modal.password.active=false">Cerrar</b-button>          
        </div>
      </b-modal>
      
      <!-- MARKETPLACE - COMISIONES -->
      <b-modal v-model="modal.commissions.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.commissions.title}}
        </div>

        <b-row>
          <b-col>
            
            <b-input-group class="w-100 mb-1">
              <b-form-input type="number"
                            tep="0.01"
                            size="md"
                            v-model="crud.commissions.commissions">
              </b-form-input>
              <b-input-group-append>
                <div class="input-group-text">
                  <b-icon class="mb-0" icon="percent"></b-icon>
                </div>
              </b-input-group-append>              
            </b-input-group>
                      
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.commissions.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="setCommisions()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Nuevos Clientes" @click="crud.formImport.type='import_store_customers'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                      <h4 class="alert-heading">Importación de Clientes</h4>
                      
                      <div>
                        <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Esta importación solo permite agregar <b>clientes</b>.
                      </div>                      
                      <div>
                        <b-icon icon="arrow-right-short"/> Si el <b>código y/o nombre</b> ya existe en la base de datos, se omite dicho registro. 
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Las <b>condición fiscal, el tipo de documento, el punto de venta y el método de pago</b>, sino existe omite la carga de dicho dato.
                      </div>
                      
                      <hr>

                      <div class="mb-0">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/customers/csv-format-customers.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>                                                                  
                    </b-alert>                  
                </b-col>
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Clientes]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
              </b-row>              
            </b-card-text>
          </b-tab>
        </b-tabs>


        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>

      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Imagen from '@/components/inc/image/image'
  import Param from '@/config/parameters'

  export default {
    components: {
      Imagen,
    },     
    data: () => {
      return {
        access: {
          module_id: Modules.CLIENTES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crud',
          elements: {
            editCustomers: true,
            deleteCustomers: true
          }
        },
        parameters: {
          codeCustomer: Helper.hasParametersAccess(Param.P2),
          isMarketplace: Helper.hasParametersAccess(Param.P18),
        },        
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          rowSelected: null,
          isBusy: false,
          tablePaginate: null,             
        },
        tableUsers : {
          items: [],
          fields: [
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'email', label:'Email', class:"align-middle"},
            {key: 'password_visible', label:'Contraseña', class:"align-middle"},
            {key: 'roles', label:'Roles', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],
        },
        tableUsersFind : {
          items: [],
          fields: [
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'email', label:'Email', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle text-right"},
          ],
        },
        arr : {
          roles: [],
          customers: [],
          exportAll: [],
          exportGoogleContacts: [],
          filter: {
            status:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ]            
          }
        },   
        filters: {          
          name: '',
          status: [
            {code:true, label: 'Activo'}
          ],          
        },
        crud: {
          form: {
            id: 0,
            name: '',
            phone: '',
            email: '',            
            image: [],
            code: '',
            active: true,
            observations: '',
            observations_delivery: '',
            observations_delivery_address: '',
          },
          formUser: {
            user_id: 0,
            customer_id: 0,
            name: '',
            email: '',
            password: '',
            roles: [],
            typePassword: 'password',
            iconPassword: 'visibility',
          },
          formUserFind: {
            customer_id: 0,
            email: '',
          },
          password: {
            name: '',
            email: '',
            password: '',
          },
          commissions: {
            customer_id: 0,
            commissions: 0,
          },
          formImport: {
            file: '',
            type: ''            
          }    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formUser: {
            active: false,
            title: ''
          },
          formUserRoles: {
            active: false,
            title: ''
          },
          formUserFind: {
            active: false,
            title: '',
            alertResult: false,
          },
          password: {
            active: false,
            title: ''            
          },
          commissions: {
            active: false,
            title: ''
          },                                  
          formImport: {
            active: false,
            title: ''
          }
        }
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.editCustomers = Helper.hasAccessElement(this.access, 'edit_customers')
      this.access.elements.deleteCustomers = Helper.hasAccessElement(this.access, 'delete_customers')
      /* Fin configuracion */
    },
    mounted() {
      this.setFieldTableCustomers()
      this.filterCustomers()
      this.cargarRoles()
      this.prepareExport()

      if(this.$route.query && 
         this.$route.query.add &&
         this.$route.query.add == 'open') {
        
        this.add()
      }      
    },
    methods: {
      // Tabla Clientes
      setFieldTableCustomers() {
        if(this.parameters.codeCustomer) {
          this.table.fields.push({key: 'code', label: 'Código', sortable: true, class:"align-middle", width:"70px"})
        } else {
          this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"50px"})
        }
        
        this.table.fields.push({key: 'image', label: 'Logo', class:"align-middle", width:"50px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"200px"})
        this.table.fields.push({key: 'phone', label: 'Teléfono', class:"align-middle", width:"150px"})
        this.table.fields.push({key: 'email', label: 'Email', class:"align-middle", width:"200px"})        
        this.table.fields.push({key: 'f_users', label:'Usuarios', class:"align-middle", width:"200px"})
        this.table.fields.push({key: 'active', label:'Activo', class:"align-middle text-center", width:"100px"})

        if(this.parameters.isMarketplace) {
          this.table.fields.push({key: 'commissions', label: 'Comisión', class:"align-middle text-center", width:"80px"})
        }

        this.table.fields.push({key: 'f_action', label:'', class:"align-middle text-right", width:"100px"})
      },
      setConfigTableCustomersShow() {
        var arrTable = document.getElementsByClassName('table-customers-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '275px'
        }        
      },
      setConfigTableCustomersHide() {
        var arrTable = document.getElementsByClassName('table-customers-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      }, 

      // Administracion clientes
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.customers = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
            this.crud.formUser.customer_id = this.table.items[this.table.rowSelected].id
            this.crud.formUserFind.customer_id = this.table.items[this.table.rowSelected].id

            if(this.table.items[this.table.rowSelected].users) {
              this.tableUsers.items = this.table.items[this.table.rowSelected].users

              this.crud.formUser.typePassword = 'text'
              this.changeTypePassword()
            }
          }
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      filterCustomers () {
        this.table.isBusy = true  
        var result = serviceAPI.filtrarCliente(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.customers = data.data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
            this.crud.formUser.customer_id = this.table.items[this.table.rowSelected].id
            this.crud.formUserFind.customer_id = this.table.items[this.table.rowSelected].id

            if(this.table.items[this.table.rowSelected].users) {
              this.tableUsers.items = this.table.items[this.table.rowSelected].users

              this.crud.formUser.typePassword = 'text'
              this.changeTypePassword()
            }
          }

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }       

          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });
      },      
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {
        var itemTMP = []
        if(item.length!=0) {
          if(item[0] == undefined) {                    
            itemTMP[0] = item                    
            item = itemTMP
          }
        }         
        this.arr.customers.forEach((element, index) => {  
          if(item.length) {              
            if(element.id == item[0].id) {

              if(this.table.items[index]._showDetails) {
                this.table.items[index]._showDetails = false
              } else {
                this.openDetail(index)
              }                       

            }
          } else {
            this.table.items[index]._showDetails = false
          }         
        })
      },
      openDetail(index) {
        this.table.rowSelected = index
        this.filterCustomers()
      },
      changeTypePassword() {
        if (this.crud.formUser.typePassword === 'password') {
          this.crud.formUser.typePassword = 'text'
          this.crud.formUser.iconPassword = 'visibility_off'
        } else {
          this.crud.formUser.typePassword = 'password'
          this.crud.formUser.iconPassword  = 'visibility'
        }
      },
      cargarRoles () {
        var result = serviceAPI.obtenerRoles()

        result.then((response) => {
          var data = response.data

          data.forEach(element => {
            if( element.profile_id == Profiles.CLIENTE ) {
              this.arr.roles.push({ value: element.id, text: element.name })
            }
          })

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.phone = ''
        this.crud.form.email = ''        
        this.crud.form.image = []
        this.crud.form.code = ''
        this.crud.form.active = true
        this.crud.form.observations = ''
        this.crud.form.observations_delivery = ''
        this.crud.form.observations_delivery_address = ''

        this.modal.form.title = "Nuevo Cliente"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.phone = (item.phone!=null) ? item.phone : ''
        this.crud.form.email = (item.email!=null) ? item.email : ''
        this.crud.form.image = item.image
        this.crud.form.code = (item.code!=null) ? item.code : ''
        this.crud.form.active = item.active
        this.crud.form.observations = item.observations
        this.crud.form.observations_delivery = item.observations_delivery
        this.crud.form.observations_delivery_address = item.observations_delivery_address

        if(item.image.length>0) {
          this.crud.form.image = [{ path: item.image }]
        } else {
          this.crud.form.image = []
        }

        this.modal.form.title = "Editar Cliente --> (" + this.crud.form.id + ")" + this.crud.form.name
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.user_id = 0

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Cliente',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.delete()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        // valida que tenga el formato correcto para emails
        if(this.crud.form.email) {
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            return false
          }       
        }

        // valida que tenga el formato para whatsapp
        if(this.crud.form.phone) {
          if(this.crud.form.phone.length != 13) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            return false
          }
        }

        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })
        
        if (this.crud.form.id) {
          var result = serviceAPI.editar(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregar(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      delete() {
        let loader = this.$loading.show();
        var result = serviceAPI.eliminar(this.crud.form.id);

        result.then((response) => {
          this.modal.formUser.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Registro eliminado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // Import By CSV
      openImportCSV() {
        this.crud.formImport.type = 'import_store_customers'
        this.crud.formImport.file = ''
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },
      importCSV() {        
        this.$bvModal.msgBoxConfirm('¿Continuar con la importación?', {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);            
              
            var result = serviceAPI.importarClientes(JSON.stringify(this.crud.formImport), file);

            result.then((response) => {              
              this.modal.formImport.active = false              
              loader.hide()
              this.filterCustomers()
              this.$awn.success("Archivo importado con éxito");
            })
            .catch(error => {    
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {         
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      },

      // Marketplace
      openCommisions(item) {
        this.crud.commissions.customer_id = item.id
        this.crud.commissions.commissions = item.commissions

        this.modal.commissions.title = "Comisión por venta"
        this.modal.commissions.active = true
      },
      setCommisions(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.setCommisions(this.crud.commissions);        

        result.then((response) => {
          this.modal.commissions.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // Administracion usuario
      filtroCliente(row) {
        var arr = []
        row.forEach(element => {
          if(element.profile_id == Profiles.CLIENTE) {
            arr.push(element)
          }
        })
        return arr
      },
      createUser (item) {
        this.crud.formUser.customer_id = item.id
        this.crud.formUser.user_id = 0
        this.crud.formUser.name = ''
        this.crud.formUser.email = ''
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []
        this.modal.formUser.title = "Asignar Usuario"
        this.modal.formUser.active = true
      },
      editRolesUser(item) {
        // this.crud.formUser.customer_id cargado desde el this.filterCustomers()
        this.crud.formUser.user_id = item.id
        this.crud.formUser.name = item.name
        this.crud.formUser.email = item.email
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []

        if(item.roles) [
          item.roles.forEach(element => {
            this.crud.formUser.roles.push(element.id)
          })
        ]

        this.modal.formUserRoles.title = "Editar Roles Usuario --> " + this.crud.formUser.name + " - " + this.crud.formUser.email
        this.modal.formUserRoles.active = true
      },
      removeUser(item) {
        // this.crud.formUser.customer_id cargado desde el this.filterCustomers()
        this.crud.formUser.user_id = item.id
        this.crud.formUser.name = item.name
        this.crud.formUser.email = item.email
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []

        this.$bvModal.msgBoxConfirm('¿Desea borrar el usuario (' + this.crud.formUser.name + " - " + this.crud.formUser.email + ')?', {
          title: 'Borrar Usuario',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteUser()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveUser() {
        let loader = this.$loading.show();
        var result = serviceAPI.crearUsuario(this.crud.formUser);

        result.then((response) => {
          this.modal.formUser.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Usuario creado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      saveUserRoles() {
        let loader = this.$loading.show();
        var result = serviceAPI.editarUsuarioRoles(this.crud.formUser);

        result.then((response) => {
          this.modal.formUserRoles.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Roles editados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      deleteUser() {
        let loader = this.$loading.show();
        var result = serviceAPI.eliminarUsuario(this.crud.formUser);

        result.then((response) => {
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Usuario desvinculado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // Buscar un usuario
      openFindUser (item) {
        this.crud.formUser.customer_id = item.id
        this.crud.formUserFind.email = ''
        this.modal.formUserFind.alertResult = false
        this.tableUsersFind.items = []

        this.modal.formUserFind.title = "Buscar Usuario"
        this.modal.formUserFind.active = true
      },
      findUser () {
        let loader = this.$loading.show();
        var result = serviceAPI.seleccionarUsuario(this.crud.formUserFind)

        result.then((response) => {
          var data = response.data
          this.tableUsersFind.items = data

          if(data.length) {
            this.modal.formUserFind.alertResult = false
          } else {
            this.modal.formUserFind.alertResult = true
          }

          loader.hide()
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
          loader.hide()
        });
      },
      selectUser (item) {
        let loader = this.$loading.show();

        var user = {  user_id: item.id,
                      customer_id: this.crud.formUser.customer_id }

        var result = serviceAPI.vincularUsuario(user);

        result.then((response) => {
          this.modal.formUserFind.active = false
          loader.hide()
          this.filterCustomers()
          this.$awn.success("Usuario creado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      viewAccess(item){
        this.crud.password.name = item.name
        this.crud.password.email = item.email
        this.crud.password.password = item.password_visible
        
        this.modal.password.title = "Accesos --> " + this.crud.password.name
        this.modal.password.active = true
      },   
      
      // Detalle view cliente
      goCustomerView(item) {
        this.$router.push({ name: 'CustomerViewStaff', params: {customersID: item.id} })
      },
      
      prepareExport() {    
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data

          this.arr.exportGoogleContacts = []
          data.forEach(element => {
            var phone = ''
            if(element.phone) {
              var phone = element.phone.replace("+", "");
            
              if(phone.substring(0,3) == '549'){
                phone = phone.substring(3)
              }
            }

            this.arr.exportGoogleContacts.push({
              'Name': element.name,
              'Given Name': '',
              'Additional Name': '',
              'Family Name': '',
              'Yomi Name': '',
              'Given Name Yomi': '',
              'Additional Name Yomi': '',
              'Family Name Yomi': '',
              'Name Prefix': '',
              'Name Suffix': '',
              'Initials': '',
              'Nickname': '',
              'Short Name': '',
              'Maiden Name': '',
              'Birthday': '',
              'Gender': '',
              'Location': '',
              'Billing Information': '',
              'Directory Server': '',
              'Mileage': '',
              'Occupation': '',
              'Hobby': '',
              'Sensitivity': '',
              'Priority': '',
              'Subject': '',
              'Notes': '',
              'Language': '',
              'Photo': '',
              'Group Membership': '* myContacts',
              'E-mail 1 - Type': '',
              'E-mail 1 - Value': element.email,
              'Phone 1 - Type': '',
              'Phone 1 - Value': phone,
              'Organization 1 - Type': '',
              'Organization 1 - Name': '',
              'Organization 1 - Yomi Name': '',
              'Organization 1 - Title': '',
              'Organization 1 - Department': '',
              'Organization 1 - Symbol': '',
              'Organization 1 - Location': '',
              'Organization 1 - Job Description': ''
            })
          });

          this.arr.exportAll = []
          data.forEach(element => {
            this.arr.exportAll.push({
              'Código': element.code,
              'Nombre': element.name,
              'Email': element.email,
              'Teléfono': element.phone,
            })
          });
        })
      },
    }
  }
</script>

<style>
  .badge-module-customers-custom {
    font-size: 14px;
  }
  .text-module-customers-custom {
    font-size: 10px;
    font-weight: 500;
  }
  .customers-text-success-custom {
    color:darkgreen;
    font-weight: 700;
  }
  .customers-text-danger-custom {
    color:darkred;
    font-weight: 700;
  }
  .customer-table-code {
    font-weight: 600;
    font-size: 11px;
  }
  .crud-customer-action-icon {
    color: #000 !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>